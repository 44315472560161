<template>
  <v-app class="bg-main-background">
    <router-view />
  </v-app>

  <v-snackbar v-model="snackbar.show" rounded="pill" location="top" color="white" width="auto" min-width="auto"
    :timeout="2000">
    <div :class="snackbar.error ? 'text-error' : 'text-primary'" class="font-weight-bold">
      <v-icon :icon="snackbar.error ? 'mdi-close-circle' : 'mdi-check-circle'"
        :color="snackbar.error ? 'error' : 'primary'"></v-icon>
      {{ snackbar.text }}
    </div>
  </v-snackbar>
</template>

<script>

export default {
  name: "App",

  data() {
    return {
      snackbar: this.$store.state.snackbar
    };
  },
};
</script>
<style lang="scss">
@use "./plugins/main.scss";

* {
  font-family: main.$body-font-family;
}

.v-application {
  [class*="text-"] {
    font-family: main.$body-font-family !important;
  }

  font-family: main.$body-font-family !important;
}
</style>

<style>
a {
  text-decoration: none;
  color: black;
}

.bg-color {
  background-color: #f8f8f8;
}

.sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 100px;
}
</style>